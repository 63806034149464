<template>
  <div>
    <h1 class="text-h4">Управление категориями</h1>
    <v-divider class="my-2"></v-divider>
    <v-form v-model="form.valid" :disabled="busy" @submit.prevent="submitForm">
      <v-card>
        <v-card-title>Редактирование категории {{ id }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="form.data.name"
            label="Название"
            counter="150"
            :rules="form.rule.name"
          />
          <v-text-field
            v-model="form.data.itemTypePrefix"
            label="Название элемента категории"
            hint="Используется при автоматической генерации имени элемента категории"
            counter="150"
          />
          <v-switch
            v-model="form.data.isActive"
            dense
            label="Включено"
            hint="Если включено, то категория становится сразу доступной на сайте"
            persistent-hint
            inset
          ></v-switch>
          <v-text-field
            v-model="form.data.sortWeight"
            :rules="form.rule.sortWeight"
            label="Вес сортировки"
            hint="Используется для сортировки"
            counter="5"
          />
          <v-switch
            v-model="form.data.useForNewOrderItem"
            dense
            label="Автокатегория для новых товаров в заказах"
            hint="Новые товары будут автоматически добавляться в эту категорию"
            persistent-hint
            inset
          ></v-switch>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :to="{name: 'category.list'}">Отмена</v-btn>
          <v-btn color="primary" :disabled="!form.valid" type="submit">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <meta-view
      :id="metadata.id"
      :loading="busy"
      :created-at="metadata.createdAt"
      :updated-at="metadata.updatedAt"
    ></meta-view>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { ruleNumeric, ruleRequired } from '@/utils/FormRules'
import { mapActions, mapState } from 'vuex'
import CategoryUpdateForm from '@/model/category/CategoryUpdateForm'
import MetaView from '@/components/ui/MetaView.vue'

export default {
  ...dashboardPageCommonAttributes,
  name: 'CategoryEditPage',
  components: { MetaView },
  props: {
    id: {
      type: [String],
      required: true
    }
  },
  data() {
    return {
      form: {
        valid: true,
        data: {
          name: '',
          itemTypePrefix: null,
          isActive: false,
          sortWeight: '0',
          useForNewOrderItem: false
        },
        rule: {
          name: [ruleRequired()],
          sortWeight: [ruleNumeric()]
        }
      },
      metadata: {
        id: null,
        createdAt: null,
        updatedAt: null
      }
    }
  },
  computed: {
    ...mapState('category_edit', ['item', 'busy'])
  },
  created() {
    this.fetchData({ id: this.id })
      .then(() => {
        this.form.data.name = this.item.name
        this.form.data.isActive = this.item.isActive
        this.form.data.itemTypePrefix = this.item.itemTypePrefix
        this.form.data.sortWeight = this.item.sortWeight
        this.form.data.useForNewOrderItem = this.item.useForNewOrderItem

        this.metadata = {
          id: this.id,
          createdAt: this.item.meta.createdAt,
          updatedAt: this.item.meta.updatedAt
        }
      })
  },
  methods: {
    ...mapActions('category_edit', ['fetchData', 'updateCategory']),
    submitForm() {
      const form = CategoryUpdateForm.create({ ...this.form.data })

      this.updateCategory({ form })
        .then(() => this.$router.push({ name: 'category.list' }))
    }
  }
}
</script>

<style scoped>

</style>
